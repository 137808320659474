import { types, getParent, flow, destroy } from "mobx-state-tree"
import { Subject } from "./models/Subject"
import { SearchMeta } from "./models/SearchMeta";
import { filterType } from "./helpers/filterType"
import { SubjectSearch } from "./models/SubjectSearch";
import { SubjectBase } from './models/SubjectBase'
import { Assessment } from './models/Assessment'
import { CreateSubject, RoleAssignment, CreateSubjectBase } from "./actions/Subject";
import { CreateAssessment } from "./actions/Assessment";
import { validationErrorsHandler } from "./helpers/errors";
import { AssessmentScore } from './models/AssessmentScore'
import { AssessmentCategory } from './models/AssessmentCategory'
import { UpdateAssessmentScore } from './actions/UpdateAssessmentScore'
import {SubjectScoresheet} from "./models/SubjectScoresheet";
import { LoadingPattern } from "./patterns/LoadingPattern";

export const SubjectStore = types.compose(
    LoadingPattern({
        isSubjectBaseListLoading: false,
        isCreateSubjectBaseLoading: false,
        isUpdateSubjectBaseLoading: false,
        isDeleteSubjectBaseLoading: false,
    }),
    types
    .model("SubjectStore", {
        subjects: types.map(Subject),
        subjectScoresheets: types.map(SubjectScoresheet),
        searchResults: types.optional(types.array(types.reference(Subject)), []),
        meta: types.optional(SearchMeta, {}),
        selectedSubjects: types.array(types.string),
        searchFormInstance: types.optional(SubjectSearch, {}),
        addSubjectFormInstance: types.optional(CreateSubject, {}),
        addAssessmentFormInstance: types.optional(CreateAssessment, {}),
        roleAssignmentFormInstance: types.optional(RoleAssignment, {}),
        subjectBases: types.map(SubjectBase),
        assessments: types.map(Assessment),
        assessmentCategories: types.map(AssessmentCategory),
        assessmentScores: types.map(AssessmentScore),
        unsavedAssessmentScores: types.map(UpdateAssessmentScore),  // to be used for offline updating
        addSubjectBaseFormInstance: types.optional(CreateSubjectBase, {}),
    })
    .views(self => ({
        get bluebic() {
            return getParent(self)
        },
        get isAllSelected() {
            return !self.meta.total_ids.some(val => self.selectedSubjects.indexOf(val) === -1)
        }
    }))
    .actions(self => {
        // function self.markLoading(loading) {
        //     self.isLoading = loading
        // }

        function updateSubjects(subjects) {
            subjects.forEach(subject => self.subjects.put(subject))
        }

        function updateSubjectScoresheet(sheets) {
            sheets.forEach(sheet => self.subjectScoresheets.put(sheet))
        }

        function updateSubjectBases(subject_bases) {
            subject_bases.forEach(subject_base => self.subjectBases.put(subject_base))
        }
        
        function updateAssessmentStore(assessments) {
            assessments.forEach(assessment => self.assessments.put(assessment))
        }
        
        function updateAssessmentCategories(items) {
            items.forEach(item => self.assessmentCategories.put(item))
        }

        function updateAssessmentScoreStore(assessment_scores) {
            assessment_scores.forEach(assessment_score => self.assessmentScores.put(assessment_score))
        }

        function updateFilteredSubjects(data) {
            updateSubjects(data)
            self.searchResults = []
            data.forEach(item => self.searchResults.push(item.id))
        }

        function markSubjectLoadingById(id, flag) {
            if (self.subjects.has(id)) {
                self.subjects.get(id).isLoading = flag
            } else {
                self.subjects.put({ id, isLoading: flag })
            }
        }

        function markAssessmentLoadingById(id, flag) {
            if (self.assessments.has(id)) {
                self.assessments.get(id).isLoading = flag
            } else {
                self.assessments.put({ id, isLoading: flag })
            }
        }

        function markSubjectScoresheetLoadingById(id, flag) {
            if (self.subjectScoresheets.has(id)) {
                self.subjectScoresheets.get(id).isLoading = flag
            } else {
                self.subjectScoresheets.put({ id })
            }
        }

        const loadSubjectById = flow(function* loadSubjectById(id) {
            try {
                markSubjectLoadingById(id, true)
                const { data, included } = yield self.bluebic.subjectService.showSubject(id)
                self.bluebic.handleUpdateStores(included)
                self.subjects.put(data)
                markSubjectLoadingById(id, false)
            } catch (err) {
                console.error(`Failed to load subject with id ${id}`, err)
                return validationErrorsHandler(err)
            }
            return null
        })

        const search = flow(function* search() {
            try {
                self.markLoading(true)
                const { data, meta, included } = yield self.bluebic.subjectService.search(self.searchFormInstance.toJSON())
                self.bluebic.handleUpdateStores(included)
                updateFilteredSubjects(data)
                self.meta = meta
                self.markLoading(false)
            } catch (err) {
                console.error("Failed to load subjects", err)
                self.markLoading(false)
                return validationErrorsHandler(err)
            }
            return null
        })

        function onUpdate(included) {
            filterType("subject", included, updateSubjects)
            filterType("subject_scoresheet", included, updateSubjectScoresheet)
            filterType("subject_base", included, updateSubjectBases)
            filterType('assessment', included, updateAssessmentStore)
            filterType('assessment_score', included, updateAssessmentScoreStore)
            filterType('assessment_category', included, updateAssessmentCategories)
        }

        function initPage() {
            return self.searchResults.length === 0 ? self.markLoading(false) : search()
        }

        const createSubject = flow(function* createSubject(subject) {
            try {
                self.markLoading(true)
                const { data } = yield self.bluebic.subjectService.createSubject(subject)
                self.subjects.put(data)
                self.markLoading(false)
                return { data }
            } catch (err) {
                console.error("Failed to create subject", err)
                self.markLoading(false)
                return validationErrorsHandler(err)
            }
        })

        const editSubject = flow(function* editSubject(subject) {
            try {
                self.markLoading(true)
                const { data } = yield self.bluebic.subjectService.editSubject(subject)
                self.subjects.put(data)
                self.markLoading(false)
                return { data }
            } catch (err) {
                console.error("Failed to edit subject", err)
                self.markLoading(false)
                return validationErrorsHandler(err)
            }
        })

        function selectSubjectById(id) {
            if (self.selectedSubjects.includes(id)) {
                const index = self.selectedSubjects.findIndex(id_ => id_ === id)
                self.selectedSubjects.splice(index, 1)
            } else {
                self.selectedSubjects.push(id)
            }
        }

        const deleteSubjectById = flow(function* deleteSubjectById(id) {
            try {
                self.markLoading(true)
                yield self.bluebic.subjectService.deleteSubject(id)
                removeSearchResultById(id)
                destroy(self.subjects.get(id))
                self.markLoading(false)
            } catch (err) {
                console.error("Failed to delete subject", err)
                self.markLoading(false)
                return validationErrorsHandler(err)
            }
            return null;
        })

        function removeSearchResultById(id) {
            const index = self.searchResults.findIndex(({id: itemId}) => itemId === id)
            return self.searchResults.splice(index, 1)
        }

        function updateSearch(model) {
            self.searchFormInstance = model
            search()
        }

        function resetAddSubjectForm() {
            self.addSubjectFormInstance = {}
        }

        function resetAddAssessmentForm() {
            self.addAssessmentFormInstance = {}
        }

        const selectSubjectForEdit = flow(function* selectSubjectForEdit(subject_id) {
            let selectedSubject = self.subjects.get(subject_id)
            if (!selectedSubject || selectedSubject.isLoading) {
                yield self.loadSubjectById(subject_id)
            }
            selectedSubject = self.subjects.get(subject_id)
            if (selectedSubject && !selectedSubject.isLoading) {
                self.addSubjectFormInstance.setFormInstance(selectedSubject)
                return Promise.resolve(selectedSubject)
            }
            return null
        })

        const assignRole = flow(function* assignRole(role) {
            try {
                self.markLoading(true)
                const { data } = yield self.bluebic.subjectService.assignRole(role)
                self.bluebic.handleUpdateStores([data])
                self.markLoading(false)
                search();
                self.roleAssignmentFormInstance.employee_id = ''
                return {data}
            } catch (err) {
                console.error("Failed to assign employee", err)
                self.markLoading(false)
                return validationErrorsHandler(err)
            }
        })

        const loadSubjectBases = flow(function* loadSubjectBases() {
            try {
                self.markLoading(true)
                const { data } = yield self.bluebic.subjectBaseService.subjectBases()
                updateSubjectBases(data)
                self.markLoading(false)
            } catch (err) {
                console.error(`Failed to get subject bases`, err)
                self.markLoading(false)
            }
        })

        const unassignEmployeeById = flow(function* unassignEmployeeById(id, special_role_assignment_id) {
            try {
                self.markLoading(true)
                yield self.bluebic.subjectService.unassignRole(id, special_role_assignment_id)
                self.markLoading(false)
                search()
            } catch (err) {
                console.error("Failed to unassign employee", err)
                self.markLoading(false)
                return validationErrorsHandler(err)
            }
            return null;
        })

        const loadAssessmentById = flow(function* loadAssessmentById(assessment_id) {
            try {
                markAssessmentLoadingById(assessment_id, true)
                const { data, included } = yield self.bluebic.assessmentService.getAssessment(assessment_id)
                self.bluebic.handleUpdateStores([data, ...included])
                markAssessmentLoadingById(assessment_id, false)
            } catch (err) {
                console.error(`Failed to load assessment with id ${assessment_id}`, err)
                markAssessmentLoadingById(assessment_id, false)
            }
        });
        
        const deleteAssessmentById = flow(function* deleteAssessmentById(id) {
            try {
                self.markLoading(true)
                yield self.bluebic.assessmentService.deleteAssessment(id)

                // destroy assessment scores
                self.assessmentScores.forEach((assessmentScore) => {
                    if (assessmentScore.relationships.assessment.data.toJSON().id === id){
                        destroy(assessmentScore) 
                    }
                })
                
                // FIXME: also delete all dashboard references
                
                
                destroy(self.assessments.get(id))
                self.markLoading(false)
            } catch (err) {
                console.error("Failed to delete subject", err)
                self.markLoading(false)
                return validationErrorsHandler(err)
            }
            return null;
        })

        const createAssessment = flow(function* createAssessment(assessment) {
            try {
                self.markLoading(true)
                const { data, included } = yield self.bluebic.subjectService.createAssessment(assessment)
                self.bluebic.handleUpdateStores([data, ...included])
                self.markLoading(false)
                return { data }
            } catch (err) {
                console.error("Failed to create assessment", err)
                self.markLoading(false)
                return validationErrorsHandler(err)
            }
        })

        function selectAssessmentForEdit(assessment_id) {
            const selectedAssessment = self.assessments.get(assessment_id)
            const { selectedSubjectId } = self.bluebic.view
            self.addAssessmentFormInstance.setFormInstance(selectedAssessment)
            self.addAssessmentFormInstance.setSubjectId(selectedSubjectId)
            return Promise.resolve(selectedAssessment)
        }

        const editAssessment = flow(function* editAssessment(assessment) {
            try {
                self.markLoading(true)
                const { data, included } = yield self.bluebic.assessmentService.editAssessment(assessment)
                self.bluebic.handleUpdateStores([data, ...included])
                self.markLoading(false)
                return { data }
            } catch (err) {
                console.error("Failed to create assessment", err)
                self.markLoading(false)
                return validationErrorsHandler(err)
            }
        })
        
        const loadScoresheetById = flow(function* loadScoresheetById(subject_id) {
            try {
                markSubjectScoresheetLoadingById(subject_id, true)
                const subjectScoresheet = self.subjectScoresheets.get(subject_id)
                const term = subjectScoresheet.filter_term
                const { data, included } = yield self.bluebic.subjectService.getScoresheet(subject_id, term)
                self.bluebic.handleUpdateStores([data, ...included])
                markSubjectScoresheetLoadingById(subject_id, false)
            } catch (err) {
                markSubjectScoresheetLoadingById(subject_id, false)
                console.error(`Failed to load subject scoresheet for subject with id ${subject_id}`, err)
            }
        });

        const processAssessmentScoreUpdate = flow(function* processAssessmentScoreUpdate(assessment_score) {
            try {
                assessment_score.status = "saving"
                
                const { data } = yield self.bluebic.assessmentService.updateAssessmentScore(assessment_score)
                
                const { subject_id } = self.assessments.get(assessment_score.assessment_id)
                const {scoresheet} = self.subjects.get(subject_id)
                self.assessmentScores.put(data)
                scoresheet.addAssessmentScore(data)
                
                return { data }
            } catch (err) {
                console.error('Failed to update assessment score', err)
                assessment_score.status = 'failed'
                if (err.errors) return validationErrorsHandler(err)
                return {errors: [{type: 'generic_app_error', title: err.toString(), detail: ''}]}
            }
        })

        const updateElectiveSubjectRegistration = flow( function * updateElectiveSubjectRegistration( id, actionInstance) {
            try {
                markSubjectLoadingById(id, true)
                actionInstance.markSaving(true)
                const { data, included } = yield self.bluebic.subjectService.updateElectiveSubjectRegistration(id, actionInstance.elective_students)
                self.bluebic.handleUpdateStores(included)
                self.subjects.put(data)
                actionInstance.markSaving(false)
                markSubjectLoadingById(id, false)
                return {data}
            } catch (err) {
                console.error("Failed to assign subject to students", err)
                actionInstance.markSaving(false)
                markSubjectLoadingById(id, false)
                return validationErrorsHandler(err)
            }
            
        })
        const updateAssessmentScore = flow(function* updateAssessmentScore(assessment_score){
            self.unsavedAssessmentScores.put(assessment_score)
            
            alert('updateAssessmentScore')
            // delete assessment score from pending list, should not be needed if code logic is accurate
            self.unsavedAssessmentScores.forEach((uas) => {
                const {student_id, assessment_id, id} = uas
                if (id !== uas.id && assessment_score.student_id === student_id && assessment_score.assessment_id === assessment_id ){
                    alert('Duplicate unsaved score')
                    console.warn('Duplicate unsaved score')
                    destroy(uas)
                }
            })
            
            const {data, errors} = yield processAssessmentScoreUpdate(assessment_score)
            if (errors) return { errors }
            if (data){
                destroy(assessment_score)
                return { data: self.assessmentScores.get(data.id)  }
            }
            
            return {}
        });

        const createSubjectBase = flow(function* createSubjectBase(subjectName) {
            try {
                self.markLoading(true)
                const { data, included } = yield self.bluebic.subjectBaseService.createSubjectBase(subjectName)
                self.bluebic.handleUpdateStores([data, ...included])
                self.markLoading(false)
                return { data }
            } catch (err) {
                console.error("Failed to create assessment", err)
                self.markLoading(false)
                return validationErrorsHandler(err)
            }
        })

        const updateSubjectBase = flow(function* updateSubjectBase(subjectName, selectedSubjectNameId) {
            try {
                self.markLoading(true)
                const { data, included } = yield self.bluebic.subjectBaseService.updateSubjectBase(subjectName, selectedSubjectNameId)
                self.bluebic.handleUpdateStores([data, ...included])
                self.markLoading(false)
                return { data }
            } catch (err) {
                console.error("Failed to create assessment", err)
                self.markLoading(false)
                return validationErrorsHandler(err)
            }
        })

        const deleteSubjectBase = flow(function* deleteSubjectBase(selectedSubjectNameId) {
            try {
                self.markLoading(true)
                const { data, included } = yield self.bluebic.subjectBaseService.deleteSubjectBase(selectedSubjectNameId)
                self.bluebic.handleUpdateStores([data, ...included])
                self.markLoading(false)
                return { data }
            } catch (err) {
                console.error("Failed to create assessment", err)
                self.markLoading(false)
                return validationErrorsHandler(err)
            }
        })
        
        return {
            createSubject,
            deleteSubjectById,
            editSubject,
            loadSubjectById,
            onUpdate,
            resetAddSubjectForm,
            search,
            selectSubjectForEdit,
            updateSearch,
            selectSubjectById,
            loadSubjectBases,
            initPage,
            assignRole,
            unassignEmployeeById,
            loadAssessmentById,
            deleteAssessmentById,
            createAssessment,
            selectAssessmentForEdit,
            editAssessment,
            loadScoresheetById,
            updateAssessmentScore,
            updateAssessmentStore,
            resetAddAssessmentForm,
            updateElectiveSubjectRegistration,
            createSubjectBase,
            updateSubjectBase,
            deleteSubjectBase,
        }
    })
)
