import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react';
import ModalForm from '../containers/ModalForm';

@inject('store')
@observer
class ReInstateEmployee extends React.Component {
    static propTypes = {
        employeeId: PropTypes.string.isRequired,
        onClose: PropTypes.func
    }

    static defaultProps = {
        onClose: () => { }
    }

    handleSubmit = () => {
        const { store, employeeId } = this.props
        const { employeeStore } = store
        const { reInstateEmployeeById } = employeeStore
        reInstateEmployeeById(employeeId)
        const { onClose } = this.props
        onClose()
    }

    render() {
        const {store : {employeeStore: {isLoading}}, onClose} = this.props

        return (
            <ModalForm
                onOk={this.handleSubmit}
                onCancel={onClose}
                okButtonText="Re-Instate Employee"
                okButtonType="success"
                isOkButtonDisabled={isLoading}
            >
                <div className="flex-row flex-alignStart mb-3">
                    <i
                        className="material-icons utils-prepend text--info"
                        style={{ paddingTop: 4 }}
                    >
                        info
                    </i>
                    <div>
                        <div className="text-h3 text--bold">
                            Are you sure you want to perform this action?
                        </div>
                        Use this option to re-instate archived employees.
                    </div>
                </div>
            </ModalForm>
        )
    }
}
export default ReInstateEmployee