import { reaction } from "mobx"
import { RouterModel } from 'mst-react-router';
import { types, getSnapshot, applySnapshot, getEnv, destroy, flow} from "mobx-state-tree"
import Cookies from 'cookie-universal';

import { FeeStore } from './FeeStore';
import { ViewStore } from './ViewStore';
import { AuthStore } from './AuthStore';
import { BatchStore } from "./BatchStore";
import { UserStore } from './UserStore';
import { StudentStore } from './StudentStore';
import { GuardianStore } from './GuardianStore';
import { EmployeeStore } from './EmployeeStore';
import { SubjectStore } from './SubjectStore';
import { FeeTransactionStore } from "./FeeTransactionStore";
import { CustomTransactionStore } from "./CustomTransactionStore";
import { WalletTransactionStore } from "./WalletTransactionStore";
import { FinanceTransactionStore } from "./FinanceTransactionStore";
import { BankAccountStore } from "./BankAccountStore";
import { EventStore } from './EventStore'
import { AttendanceStore } from './AttendanceStore'
import { MessageStore } from "./MessageStore";
import { Announcement } from './models/Announcement'
import { DashboardReport } from './models/DashboardReport';
import { ReportCardGroupStore } from './ReportCardGroupStore';
import { Institution } from "./models/Institution"

import axios, { setAuthHeader } from '../service/axios';
import { FeeService } from '../service/FeeService';
import { NoteService } from '../service/NoteService';
import { AuthService } from '../service/AuthService';
import { AssessmentService } from '../service/AssessmentService';
import { StudentService } from '../service/StudentService';
import { FinanceService } from '../service/FinanceService';
import { UserService } from '../service/UserService';
import { EmployeeService } from '../service/EmployeeService';
import { SubjectService } from '../service/SubjectService';
import { SubjectBaseService } from '../service/SubjectBaseService';
import { GuardianService } from '../service/GuardianService';
import { FeeTransactionService } from '../service/FeeTransactionService';
import { CustomTransactionService } from '../service/CustomTransactionService';
import { WalletTransactionService } from "../service/WalletTransactionService";
import { FinanceTransactionService } from '../service/FinanceTransactionService';
import { EventService } from '../service/EventService';
import { AttendanceService } from '../service/AttendanceService';
import { BatchService } from "../service/BatchService";
import { DashboardService } from '../service/DashboardService';
import { ReportCardGroupService } from '../service/ReportCardGroupService';
import { MessageService } from "../service/MessageService";
import { BlueBicMetaService } from "../service/BlueBicMetaService";

import { BluebicMeta } from './models/BluebicMeta'
import { StudentGuardianRelation } from './models/StudentGuardianRelation';
import { StudentFeeDiscount} from './models/StudentFeeDiscount';
import { SpecialRoleAssignment } from "./models/SpecialRoleAssignment";
import { BehaviouralDomainScore } from "./models/BehaviouralDomainScore";
import { filterType } from "./helpers/filterType";
import { titleCase } from '../lib/titleCase'
import { mapModelNameToOptionsWithStringValue} from '../lib/mapModelNameToOptions'
import { PAYSTACK_PUBLIC_KEY, PAYSTACK_TEST_PUBLIC_KEY, PRODENV} from '../service/constants'
import { formatCurrency as formatCurrencyLib } from '../lib/formatCurrency';
import { CourseStore } from './CourseStore';
import { CourseSetService } from '../service/CourseSetService';
import { ReportCardTemplateService } from '../service/ReportCardTemplateService';
import {SpecialRole} from "./models/SpecialRole";
import {AttachedDocument} from "./models/AttachedDocument";
import {AcademicSessionService} from "../service/AcademicSessionService";
import {AcademicSessionStore} from "./AcademicSessionStore";
import {SmsMaster} from "./models/SmsMaster";
import {SmsTopupStore} from "./SmsTopupStore";

const cookies = new Cookies();

export const routerModel = RouterModel.create();

export const BluebicStore = types
	.model("Bluebic", {
		router: types.optional(RouterModel, () => RouterModel.create()),
        view: types.optional(ViewStore, {}),
        institutions: types.map(Institution),
        smsMasters: types.map(SmsMaster),
        userStore: types.optional(UserStore, { users: {} }),
        studentStore: types.optional(StudentStore, { students: {} }),
		guardianStore: types.optional(GuardianStore, {}),
        studentGuardianRelations: types.map(StudentGuardianRelation),
		employeeStore: types.optional(EmployeeStore, { employees: {} }),
        subjectStore: types.optional(SubjectStore, { subjects: {} }),
        courseStore: types.optional(CourseStore, {}),
		financeTransactionStore: types.optional(FinanceTransactionStore, { financeTransactions: {} }),
		feeTransactionStore: types.optional(FeeTransactionStore, { feeTransactions: {} }),
		customTransactionStore: types.optional(CustomTransactionStore, { customTransactions: {} }),
		walletTransactionStore: types.optional(WalletTransactionStore, { walletTransactions: {} }),
		bankAccountStore: types.optional(BankAccountStore, { bankAccounts: {} }),
		feeStore: types.optional(FeeStore, {}),
        studentFeeDiscounts: types.map(StudentFeeDiscount),
        batchStore: types.optional(BatchStore, {}),
        attendanceStore: types.optional(AttendanceStore, {}),
        eventStore: types.optional(EventStore, { EventStore: {} }),
        messageStore: types.optional(MessageStore, { MessageStore: {} }),
        smsTopupStore: types.optional(SmsTopupStore, { MessageStore: {} }),
        authStore: types.optional(AuthStore, {}),
		defaultPaymentMethods: types.optional(types.array(types.string), ['cash', 'bank_teller', 'student_wallet', 'web_pay']),
        dashboardReport: types.optional(DashboardReport, {}),
        announcements: types.map(Announcement),
        specialRoleAssignments: types.map(SpecialRoleAssignment),
        attachedDocuments: types.map(AttachedDocument),
        behaviouralDomainScores: types.optional(types.map(BehaviouralDomainScore), {}),
        specialRoles: types.map(SpecialRole),
        reportCardGroupStore: types.optional(ReportCardGroupStore, { report_card_groups: {} }),
        meta: types.optional(BluebicMeta, {}),
        academicSessionStore: types.optional(AcademicSessionStore, {}),
		rememberMe: false
	})
	.views(self => ({
		get env() {
			return getEnv(self).env
		},
		get fetch() {
			return getEnv(self).fetch
		},
		get alert() {
			return getEnv(self).alert
		},
		get isLoading() {
			return false
		},
		get paystackPublicKey() {
		    // TODO: use test key in production for demo institution
            if (self.env === PRODENV && !self.meta.currentInstitution.isDemoInstitution) return PAYSTACK_PUBLIC_KEY
            return PAYSTACK_TEST_PUBLIC_KEY
		},
		get academicSessions() {
			return self.academicSessionStore.academicSessions
		},
		get users() {
			return self.userStore.users
		},
		get students() {
			return self.studentStore.students
		},
		get guardians() {
			return self.guardianStore.items
        },
        get batches() {
            return self.batchStore.items
        },
		get messages() {
			return self.messageStore.messages
        },
		get employees() {
			return self.employeeStore.employees
        },
        get subjects() {
			return self.subjectStore.subjects
		},
		get financeTransactions() {
			return self.financeTransactionStore.financeTransactions
		},
		get events() {
			return self.eventStore.events
        },
        get fees(){
            return self.feeStore.items
        },
		get userService() {
			return UserService(self.env)
		},
		get studentService() {
			return StudentService(self.env)
		},
		get employeeService() {
			return EmployeeService(self.env)
        },
        get subjectService() {
			return SubjectService(self.env)
        },
        get subjectBaseService() {
			return SubjectBaseService(self.env)
		},
		get guardianService() {
			return GuardianService(self.env)
		},
		get noteService() {
			return NoteService(self.env)
		},
		get financeService() {
			return FinanceService(self.env)
		},
		get financeTransactionService() {
			return FinanceTransactionService(self.env)
		},
		get feeTransactionService() {
			return FeeTransactionService(self.env)
		},
		get customTransactionService() {
			return CustomTransactionService(self.env)
		},
		get walletTransactionService() {
			return WalletTransactionService(self.env)
		},
		get feeService() {
			return FeeService(self.env)
        },
        get batchService() {
            return BatchService(self.env)
        },
        get attendanceService() {
            return AttendanceService(self.env)
        },
		get authService() {
			return AuthService(self.env)
        },
		get bluebicMetaService() {
			return BlueBicMetaService(self.env)
        },
        get assessmentService() {
			return AssessmentService(self.env)
        },
        get reportCardGroupService() {
			return ReportCardGroupService(self.env)
        },
        get reportCardTemplateService() {
			return ReportCardTemplateService(self.env)
        },
        get courseSetService() {
			return CourseSetService(self.env)
        },
        get dashboardService() {
			return DashboardService(self.env)
        },
        get eventService() {
			return EventService(self.env)
        },
        get messageService() {
			return MessageService(self.env)
        },
        get academicSessionService() {
            return AcademicSessionService(self.env)
        },
		get isUserLoggedIn() {
			return self.authStore.hasToken
		},
        get currency() {
            const { meta: { currentInstitution: { attributes: { currency: curr } } } } = self
            return curr
        },
        formatCurrency(amount) {
            return formatCurrencyLib(amount, self.currency)
        },
        get paymentMethodOptions(){
            return self.defaultPaymentMethods.map((paymentMethod) => ({
                text: titleCase(paymentMethod),
                value: paymentMethod
            })).concat(
                mapModelNameToOptionsWithStringValue(self.meta.attributes.custom_payment_methods)
            )
        },
        get offlinePaymentMethodOptions(){
            return self.defaultPaymentMethods.filter((value) => (value !== 'web_pay')).map((paymentMethod) => ({
                text: titleCase(paymentMethod),
                value: paymentMethod
            })).concat(
                mapModelNameToOptionsWithStringValue(self.meta.attributes.custom_payment_methods)
            )
        },
        get onlinePaymentMethodOptions(){
            return [{
                text: 'Pay Online',
                value: 'web_pay'
            }]
        }
	}))
	.actions(self => {

        const getBlueBicMeta = flow(function* getBlueBicMeta() {
            try {
                self.meta.markLoading(true)
                const { data: meta, included: meta_included } = yield self.bluebicMetaService.getBlueBicMeta()
                self.handleUpdateStores(meta_included)
                self.setMetaData(meta)
                self.meta.markLoading(false)
            } catch (err) {
                // self.markLoading(false)
                console.error(`Failed to load BlueBic Meta`, err)
            }
        })
        
        function updateStudentGuardianRelations(relations) {
            relations.forEach((relation) => {
                self.studentGuardianRelations.put(relation)
            })
        }
        function updateStudentFeeDiscounts(discounts) {
            discounts.forEach((discount) => self.studentFeeDiscounts.put(discount))
        }
        function updateAnnouncements(announcements) {
            announcements.forEach((announcement) => self.announcements.put(announcement))
        }

        function updateSpecialRoleAssignment(specialRoleAssignments) {
            specialRoleAssignments.forEach(specialRoleAssignment => self.specialRoleAssignments.put(specialRoleAssignment))
        }

        function updateBehaviouralScores(behaviouralDomainScores) {
            behaviouralDomainScores.forEach(behaviouralDomainScore => self.behaviouralDomainScores.put(behaviouralDomainScore))
        }

        function updateSpecialRoles(specialRoles) {
            specialRoles.forEach(specialRole => self.specialRoles.put(specialRole))
        }

        function updateSmsMasters(smsMasters) {
            smsMasters.forEach(smsMaster => self.smsMasters.put(smsMaster))
        }

        function updateAttachedDocuments(docs) {
            docs.forEach(doc => self.attachedDocuments.put(doc))
        }

        function updateInstitutions(institutions) {
            institutions.forEach((institution) => self.institutions.put(institution))
        }

        function updateUsers(users) {
            users.forEach((user) => self.users.put(user))
        }

        function handleUpdateStores(included) {
            if (!included) return

            filterType("institution", included, updateInstitutions)
            filterType("user", included, updateUsers)
            filterType("student_guardian_relation", included, updateStudentGuardianRelations)
            filterType("student_fee_discount", included, updateStudentFeeDiscounts)
            filterType("announcement", included, updateAnnouncements)
            filterType("special_role_assignment", included, updateSpecialRoleAssignment)
            filterType("behavioural_domain_score", included, updateBehaviouralScores)
            filterType("special_role", included, updateSpecialRoles)
            filterType("attached_document", included, updateAttachedDocuments)
            filterType("sms_master", included, updateSmsMasters)
            self.batchStore.onUpdate(included)
            self.userStore.onUpdate(included)
            self.studentStore.onUpdate(included)
            self.guardianStore.onUpdate(included)
            self.employeeStore.onUpdate(included)
            self.bankAccountStore.onUpdate(included)
            self.feeStore.onUpdate(included)
            self.academicSessionStore.onUpdate(included)
            self.feeTransactionStore.onUpdate(included)
            self.customTransactionStore.onUpdate(included)
            self.walletTransactionStore.onUpdate(included)
            self.financeTransactionStore.onUpdate(included)
            self.subjectStore.onUpdate(included)
            self.eventStore.onUpdate(included)
            self.messageStore.onUpdate(included)
            self.attendanceStore.onUpdate(included)
            self.reportCardGroupStore.onUpdate(included)
            self.courseStore.onUpdate(included)
        }

        function logout() {
            self.authStore.logout()
            self.view.openLoginPage()
            if (window.Beacon) window.Beacon('logout', { endActiveChat: false })
            if (typeof window.mixpanel === 'object') window.mixpanel.reset()
        }

        // eslint-disable-next-line consistent-return
        function errorResponseHandler(e) {
            const { config, response } = e
            if (
                Object.prototype.hasOwnProperty.call(config, 'errorHandle') &&
                config.errorHandle === false
            ) {
                return Promise.reject(e);
            }

            if (response) {
                let error
                if (response.data.error) {
                    error = response.data.error.title
                }
                if (response.data.errors) {
                    const { status_code, title } = response.data.errors[0]
                    error = title
                    if (status_code === 401) {
                        self.logout()
                    }
                }
                if (error) self.alert({ error })
                console.log("Global Service Error Handler: ", error, response.data);
                return Promise.reject(response.data)
            }
        }
 
        function flashResponseHandler(response) {
            const { data: {flash} } = response
            if (flash) {
                if (flash) {
                    self.alert(flash)
                    console.log("Global Service Flash Handler: ", flash, response.data);
                }
            }
            return response
        }

        function afterCreate() {
            axios.interceptors.response.use(flashResponseHandler, errorResponseHandler)
            
            if (self.meta.currentUser) self.authStore.setAnalyticsUser()

            if (typeof window !== "undefined" && window.localStorage && window.sessionStorage) {
                self.rememberMe = JSON.parse(window.localStorage.getItem("bluebic_remember_me")) || false
                readFromStorage()
                reaction(
                    () => getSnapshot(self),
                    json => writeToStorage(json)
                )
                reaction(
                    () => self.rememberMe,
                    flag => window.localStorage.setItem("bluebic_remember_me", flag)
                )
                const { accessToken } = self.authStore
                setAuthHeader(accessToken)
            }
        }

        function readFromStorage() {
            try {
                const bluebicDomain = window.localStorage.getItem("bluebic_domain")
                if (bluebicDomain) applySnapshot(self, JSON.parse(bluebicDomain))

                const bluebicView = window.sessionStorage.getItem("bluebic_view")
                if (bluebicView) applySnapshot(self.view, JSON.parse(bluebicView))

                const access_token = cookies.get('access_token')
                if (access_token) self.authStore.accessToken = access_token
            } catch (e) {
                console.error(e)
                self.logout()
            }
        }

        function writeToStorage(data) {
            const {view, authStore, ...domain} = data
            const domainSerialized = JSON.stringify(domain)
            const viewSerialized = JSON.stringify(view)
            
            window.localStorage.setItem("bluebic_domain", domainSerialized)
            window.sessionStorage.setItem("bluebic_view", viewSerialized)
        }

        function clearStorage() {
            applySnapshot(self, {})
            cookies.remove('access_token')
        }

        function setMetaData(meta_data) {
            self.meta = meta_data
        }

        function clearMetaData() {
            destroy(self.meta)
        }

        function toggleRememberMe() {
            self.rememberMe = !self.rememberMe
        }

        return {
            afterCreate,
            clearMetaData,
            clearStorage,
            handleUpdateStores,
            logout,
            setMetaData,
            getBlueBicMeta,
            toggleRememberMe,
        }
    });
