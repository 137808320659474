import './EmployeeBioData.css'

import React from 'react'
import { Typography, Row, Col } from 'antd'
import { observer, inject } from 'mobx-react'
import Card from '../common/card'
import { titleCase } from '../lib/titleCase'
import person from './assets/person.svg'
import address_book from './assets/contacts.svg'
import map_pin from './assets/map-pin.svg'
import bank from './assets/bank.svg'
import people from './assets/people.svg'
import Loading from '../common/loading/BioDataLoading'

const { Text } = Typography

const EmployeeBioData = inject("store")(
    observer(({ employee }) => {
        const { isLoading, bioData: {employeeProfile, contacts, address, nextOfKin, bankAccount} } = employee

        function renderGroup(data) {
            return Object.keys(data).map((key, idx) => (
                <React.Fragment key={key}>
                    {idx > 0 && <div className="divider my-1" />}
                    <Row>
                        <Col span={7}>
                            <span className="text-small text--bold">{key.toUpperCase()}</span>
                        </Col>
                        <Col>
                            <Text>{titleCase(data[key])}</Text>
                        </Col>
                    </Row>
                </React.Fragment>
            ))
        }
        return (
            <div className="EmployeeBioData">
                <Card
                    title={(
                        <div className="flex-row">
                            <img src={person} className="utils-prepend" alt="" />
                            Employee Profile
                        </div>
                    )}
                >
                    {isLoading ? <Loading /> :renderGroup(employeeProfile)}
                </Card>
                <Card
                    title={(
                        <div className="flex-row">
                            <img src={address_book} className="utils-prepend" alt="" />
                            Contacts
                        </div>
                    )}
                >
                    {isLoading ? <Loading /> :renderGroup(contacts)}
                </Card>
                <Card
                    title={(
                        <div className="flex-row">
                            <img src={map_pin} className="utils-prepend" alt="" />
                            Address
                        </div>
                    )}
                >
                    {isLoading ? <Loading /> :titleCase(address)}
                </Card>
                <Card
                    title={(
                        <div className="flex-row">
                            <img src={people} className="utils-prepend" alt="" />
                            Next of Kin
                        </div>
                    )}
                >
                    {isLoading ? <Loading /> :renderGroup(nextOfKin)}
                </Card>
                <Card
                    title={(
                        <div className="flex-row">
                            <img src={bank} className="utils-prepend" alt="" />
                            Bank Account
                        </div>
                    )}
                >
                    {isLoading ? <Loading /> :renderGroup(bankAccount)}
                </Card>
            </div>
        )
    })
)   

export default EmployeeBioData