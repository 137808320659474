import { types, flow, applySnapshot, destroy } from "mobx-state-tree"
import FileSaver from "file-saver";
import * as ReactGA from "react-ga";
import isEmpty from 'lodash.isempty';

import { Employee } from "./models/Employee"
import { SearchMeta } from "./models/SearchMeta";
import { EmployeeSearch } from "./models/EmployeeSearch";
import { CreateEmployee } from "./actions/Employee";
import { LoadingPattern } from "./patterns/LoadingPattern";
import { UsesBluebicStorePattern } from "./patterns/UsesBluebicStorePattern";
import { filterType } from "./helpers/filterType";
import { validationErrorsHandler } from "./helpers/errors";

export const EmployeeStore = types
    .compose(
        LoadingPattern(),
        UsesBluebicStorePattern,
        types
            .model("EmployeeStore", {
                employees: types.map(Employee),
                searchResults: types.optional(
                    types.array(types.reference(Employee)),
                    []
                ),
                activeEmployees: types.optional(
                    types.array(types.reference(Employee)),
                    []
                ),
                meta: types.optional(SearchMeta, {}),
                selectedEmployees: types.array(types.string),
                searchFormInstance: types.optional(EmployeeSearch, {}),
                addEmployeeFormInstance: types.optional(CreateEmployee, {})
            })
            .views(self => ({
                get isAllSelected() {
                    return !self.meta.total_ids.some(val => self.selectedEmployees.indexOf(val) === -1)
                },
                get service() {
                    return self.bluebic.employeeService
                }
            }))
            .actions(self => {

                function updateEmployees(employees) {
                    employees.forEach(json => self.employees.put(json))
                }

                function updateFilteredEmployees(data) {
                    updateEmployees(data)
                    self.searchResults = []
                    data.forEach(item => self.searchResults.push(item.id))
                }

                function updateActiveEmployees(data) {
                    updateEmployees(data)
                    data.forEach(item => self.activeEmployees.push(item.id))
                }

                function markEmployeeLoadingById(id, flag) {
                    if (self.employees.has(id)) {
                        self.employees.get(id).isLoading = flag
                    } else {
                        self.employees.put({ id })
                    }
                }

                const loadEmployeeById = flow(function* loadEmployeeById(id) {
                    try {
                        markEmployeeLoadingById(id, true)
                        const { data, included } = yield self.bluebic.employeeService.showEmployee(id)
                        self.bluebic.handleUpdateStores(included)
                        self.employees.put(data)
                        markEmployeeLoadingById(id, false)
                        return {data: self.employees.get(id)}
                    } catch (err) {
                        console.error(`Failed to load employee with id ${id}`, err)
                        markEmployeeLoadingById(id, false)
                        return {error: `Failed to load employee with id ${id}`}
                    }
                })

                const search = flow(function* search() {
                    try {
                        self.markLoading(true)
                        const { data, meta, included } = yield self.bluebic.employeeService.search(self.searchFormInstance.toJSON())
                        self.bluebic.handleUpdateStores(included)
                        updateFilteredEmployees(data)
                        self.meta = meta
                        self.markLoading(false)
                    } catch (err) {
                        self.markLoading(false)
                        console.error("Failed to load employees", err)
                    }
                })
                const archiveEmployeeById = flow(function* archiveEmployeeById(employee_id, archiveEmployee) {
                    try {
                        self.markLoading(true)
                        const { data } = yield self.bluebic.employeeService.archiveEmployee(employee_id, archiveEmployee)
                        self.employees.get(employee_id).attributes = data.attributes
                        self.markLoading(false)
                        return {data}
                    } catch (err) {
                        self.markLoading(false)
                        console.error("Failed to archive employee", err)
                        return validationErrorsHandler(err)
                    }
                })
                
                const reInstateEmployeeById = flow(function* reInstateEmployeeById(id) {
                    try {
                        self.markLoading(true)
                        const { data, included } = yield self.bluebic.employeeService.reInstateEmployeeById(id)
                        self.bluebic.handleUpdateStores(included)
                        self.employees.put(data)
                        self.markLoading(false)
                    } catch (err) {
                        self.markLoading(false)
                        console.error("Failed to Re-Instate Employee", err)
                    }
                })
                
                const deleteEmployeeById = flow(function* deleteEmployeeById(employee_id) {
                    try {
                        self.markLoading(true)
                        const { data } = yield self.bluebic.employeeService.deleteEmployee(employee_id)
                        const index = self.searchResults.findIndex(({ id }) => id === employee_id)
                        if(index >= 0)self.searchResults.splice(index,1)
                        destroy(self.employees.get(employee_id))
                        self.bluebic.view.openEmployeesPage()
                        self.markLoading(false)
                        return  {data}
                    } catch (err) {
                        console.error("Failed to delete employee", err)
                        self.markLoading(false)
                        return validationErrorsHandler(err)
                    }
                })
                const loadActiveEmployees = flow(function* loadActiveEmployees(page){
                    const searchData = {
                        page,
                        per_page: 1000,
                    }
                    try {
                        self.markLoading(true)
                        const { data, meta, included } = yield self.bluebic.employeeService.search(searchData)
                        self.bluebic.handleUpdateStores(included)
                        if (page === 1) {
                            self.activeEmployees = []
                        }
                        updateActiveEmployees(data)
                        self.meta = meta
                        if (page < meta.total_pages) {
                            loadActiveEmployees(page + 1)
                        }
                        self.markLoading(false)
                    } catch (err) {
                        console.error("Failed to load active employees", err)
                        self.markLoading(false)
                        return validationErrorsHandler(err)
                    }
                    return null
                })

                function onUpdate(included) {
                    filterType("employee", included, updateEmployees)
                }

                const createEmployee = flow(function* createEmployee(employee) {
                    try {
                        self.markLoading(true)
                        const { data, included } = yield self.bluebic.employeeService.createEmployee(employee)
                        self.bluebic.handleUpdateStores(included)
                        self.employees.put(data)
                        self.markLoading(false)
                        return { data }
                    } catch (err) {
                        self.markLoading(false)
                        console.error("Failed to create employee", err)
                        return validationErrorsHandler(err)
                    }
                })

                const editEmployee = flow(function* editEmployee(employee) {
                    try {
                        self.markLoading(true)
                        const { data } = yield self.bluebic.employeeService.editEmployee(employee)
                        self.employees.put(data)
                        self.markLoading(false)
                        return { data }
                    } catch (err) {
                        console.error("Failed to edit employee", err)
                        self.markLoading(false)
                        return validationErrorsHandler(err)
                    }
                })

                const addNote = flow(function* addNote(employee_id, action) {
                    try {
                        self.markLoading(true)
                        const { data } = yield self.bluebic.noteService.addEmployeeNote(employee_id, action)
                        self.employees.get(employee_id).attributes.notes.push(data)
                        self.markLoading(false)
                    } catch (err) {
                        self.markLoading(false)
                        console.error("Failed to create note", err)
                    }
                })

                const editNote = flow(function* editNote(employee_id, note_id, action) {
                    try {
                        self.markLoading(true)
                        const { data } = yield self.bluebic.noteService.updateNote(note_id, action)
                        const { notes } = self.employees.get(employee_id).attributes
                        const index = notes.findIndex(({ id }) => id === note_id)
                        applySnapshot(notes[index], data)
                        self.markLoading(false)
                    } catch (err) {
                        self.markLoading(false)
                        console.error("Failed to edit note", err)
                    }
                })

                const removeNote = flow(function* removeNote(employee_id, note_id) {
                    try {
                        self.markLoading(true)
                        yield self.bluebic.noteService.removeNote(note_id)
                        const { notes } = self.employees.get(employee_id).attributes
                        const index = notes.findIndex(({ id }) => id === note_id)
                        destroy(notes[index])
                        self.markLoading(false)
                    } catch (err) {
                        self.markLoading(false)
                        console.error("Failed to remove note", err)
                    }
                })

                function selectEmployeeById(id) {
                    if (self.selectedEmployees.includes(id)) {
                        const index = self.selectedEmployees.findIndex(id_ => id_ === id)
                        self.selectedEmployees.splice(index, 1)
                    } else {
                        self.selectedEmployees.push(id)
                    }
                }

                function selectAllEmployees() {
                    if (self.isAllSelected) {
                        self.selectedEmployees.length = 0
                    } else {
                        self.meta.total_ids.forEach(id => {
                            if (!self.selectedEmployees.includes(id)) {
                                self.selectedEmployees.push(id)
                            }
                        })
                    }
                }

              
                const updatePrivilegesById = flow(function* updatePrivilegesById(id, update_employee_privileges) {
                    try {
                        self.markLoading(true)
                        const { data, included } = yield self.bluebic.employeeService.updatePrivileges(id, update_employee_privileges)
                        self.employees.put(data)
                        self.employees.get(id).isLoading = false
                        self.bluebic.handleUpdateStores(included)
                        self.markLoading(false)
                    } catch (err) {
                        self.markLoading(false)
                        console.error("Failed to update employee privileges", err)
                    }
                })
                
                const downloadEmployeeListSpreadsheet = flow(function* downloadEmployeeListSpreadsheet(action){
                    try{
                        action.markSaving(true)
                        const {client_state, ...action_without_client} = action
                        const { fileData, fileName } = yield self.service.downloadEmployeeListSpreadsheet(action_without_client)
                        action.markSaving(false)
                        FileSaver.saveAs(fileData, fileName);
                        self.bluebic.alert({success: 'Download complete.'})
                        ReactGA.event({
                            category: 'Spreadsheet Export',
                            action: 'Exported Employee Data',
                            nonInteraction: false,
                            value: action.employee_ids.length,
                            // 'label: 'Game Widget'',
                        });
                    } catch (err) {
                        console.error(`Failed to Initiate Spreadsheet download of employee list`, err)
                        action.markSaving(false)
                    }
                })

                function updateSearch(model) {
                    self.searchFormInstance = model
                    search()
                }

                function resetAddEmployeeForm() {
                    self.addEmployeeFormInstance = {}
                }

                const selectEmployeeForEdit = flow(function* selectEmployeeForEdit(employee_id) {
                    let selectedEmployee = self.employees.get(employee_id)
                    if (isEmpty(selectedEmployee) || !selectedEmployee.isAssociationsLoaded) {
                        yield self.loadEmployeeById(employee_id)
                        selectedEmployee = self.employees.get(employee_id)
                    }
                    self.addEmployeeFormInstance.setFormInstance(selectedEmployee)
                    return Promise.resolve(selectedEmployee)
                })

                return {
                    addNote,
                    createEmployee,
                    archiveEmployeeById,
                    reInstateEmployeeById,
                    deleteEmployeeById,
                    editEmployee,
                    editNote,
                    loadEmployeeById,
                    onUpdate,
                    removeNote,
                    resetAddEmployeeForm,
                    search,
                    selectAllEmployees,
                    selectEmployeeById,
                    selectEmployeeForEdit,
                    updatePrivilegesById,
                    updateSearch,
                    loadActiveEmployees,
                    downloadEmployeeListSpreadsheet
                }
            })
    )
