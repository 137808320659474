import React from 'react'
import { inject, observer } from 'mobx-react'
import { Field, Form, converters } from "mstform";
import * as PropTypes from "prop-types";
import ModalForm from '../containers/ModalForm'
import { UpdatePassword } from '../store/actions/UpdatePassword'
import Label from './form/Label';
import TextInput from './form/TextInput';
import InlineError from './form/InlineError';

@inject('store')
@observer
class UpdatePasswordComponent extends React.Component {
    componentWillMount() {
        const { id, store } = this.props
        const { userStore } = store
        const { updateUserPassword } = userStore

        const form = new Form(UpdatePassword, {
            current_user_password: new Field(converters.string, { required: true }),
            new_password: new Field(converters.string, { required: true }),
            new_password_confirmation: new Field(converters.string, { required: true }),
        })
        const formInstance = UpdatePassword.create({})
        this.formState = form.state(formInstance, {
            addMode: true,
            save: async (node) => {
                const { errors } = await updateUserPassword(id, node.toJSON())
                return errors
            }
        })
    }

    handleSubmit = async () => {
        const { onCancel } = this.props
        const success = await this.formState.save()
        if (success) {
            onCancel()
        }
    }

    render() {
        const { store, onCancel } = this.props
        const { authStore, meta: {currentUser} } = store
        const { attributes: {username: currentUserUsername} } = currentUser
        const { isLoading } = authStore

        const current_user_password = this.formState.field("current_user_password")
        const new_password = this.formState.field("new_password")
        const new_password_confirmation = this.formState.field("new_password_confirmation")
        return (
            <ModalForm
                isOkButtonDisabled={!this.formState.isValid || isLoading}
                okButtonText={isLoading ? "Please wait..." : "Update Password"}
                onOk={this.handleSubmit}
                onCancel={onCancel}
            >
                <Label text={`Current Password for User: ${currentUserUsername}`} required={current_user_password.required}>
                    <InlineError field={current_user_password}>
                        <TextInput {...current_user_password.inputProps} />
                    </InlineError>
                </Label>
                <Label text="New Password" required={new_password.required}>
                    <InlineError field={new_password}>
                        <TextInput {...new_password.inputProps} />
                    </InlineError>
                </Label>
                <Label text="Enter new password again" required={new_password_confirmation.required}>
                    <InlineError field={new_password_confirmation}>
                        <TextInput {...new_password_confirmation.inputProps} handleReturnKey={this.handleSubmit} />
                    </InlineError>
                </Label>
            </ModalForm>
        )
    }
}
UpdatePasswordComponent.propTypes = {
    id: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired
}
export default UpdatePasswordComponent