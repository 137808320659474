import axios from './axios'
import { TESTENV } from './constants'
import { stringifyQuery } from '../lib/stringifyQuery';
import searchJSON from '../json/employees.json'
import showEmployeeJSON from '../json/employee_data.json'
import updateEmployeePrivilegesJSON from '../json/update_employee_privileges.json'
import createEmployeeJSON from '../json/create_employee_success.json'
import editEmployeeJSON from '../json/edit_employee_success.json'
import { formDataTransform } from './formDataTransform';
import archiveEmployeeJSON from "../json/archive_employee_success";
import deleteEmployeeJSON from "../json/delete_employee_success";

export function EmployeeService(env) {
    async function search(employee_search) {
        if (env !== TESTENV) {
            const queryString = stringifyQuery("employee_search", employee_search)
            const { data } = await axios.get(`/api/v1/employees?${queryString}`)
            return data
        }
        return searchJSON
    }

    async function showEmployee(id) {
        if (env !== TESTENV) {
            const { data } = await axios.get(`/api/v1/employees/${id}`)
            return data
        }
        return showEmployeeJSON
    }

    async function updatePrivileges(id, employee) {
        if (env !== TESTENV) {
            const { data } = await axios.put(`/api/v1/employees/${id}/privileges`, { employee })
            return data
        }
        return updateEmployeePrivilegesJSON
    }

    async function createEmployee(employee) {
        if (env !== TESTENV) {
            const { data } = await axios.post(
                `/api/v1/employees`,
                { employee },
                { transformRequest: formDataTransform }
            )
            return data
        }
        return createEmployeeJSON
    }

    async function editEmployee(employee) {
        if (env !== TESTENV) {
            const { data } = await axios.put(
                `/api/v1/employees/${employee.id}`,
                { employee },
                { transformRequest: formDataTransform }
            )
            return data
        }
        return editEmployeeJSON
    }

    async function deleteEmployee(id) {
        if (env !== TESTENV) {
            const {data} = await axios.delete(`/api/v1/employees/${id}`);
            return data
        }
        return deleteEmployeeJSON
    }
    
    async function archiveEmployee(id, archive_employee) {
        if (env !== TESTENV) {
            const {data} = await axios.patch(`/api/v1/employees/${id}/to_archive`, {archive_employee});
            return data
        }
        return archiveEmployeeJSON
    }
    
    async function reInstateEmployeeById(id) {
        if (env !== TESTENV) {
            const { data } = await axios.patch(`/api/v1/employees/${id}/restore_to_active`)
            return data
        }
        return showEmployeeJSON
    }

    async function downloadEmployeeListSpreadsheet(action) {
        const { data: fileData, headers } = await axios({
            url: `/resources/employees`,
            method: 'POST',
            responseType: 'blob',
            headers: {'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'},
            data: action
        })

        const contentDisposition = headers['content-disposition'];
        let fileName = 'unknown';
        if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
            // eslint-disable-next-line prefer-destructuring
            if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
        }

        return {fileData, fileName}
    }
    


    return {
        createEmployee,
        editEmployee,
        search,
        showEmployee,
        deleteEmployee,
        archiveEmployee,
        reInstateEmployeeById,
        updatePrivileges,
        downloadEmployeeListSpreadsheet
    }
}

export default EmployeeService(TESTENV)